<template>
  <ion-page>
    <ion-content>
      <div class="ion-padding">
        <div v-if="installStatus === STATUS_RUNNING">
          <DetectConvertorForm v-if="!convertor"
                               @selected="convertor = $event" />
          <InstallConvertorForm v-else
                                ref="form"
                                :form="form"
                                :hardware-address="convertor.hardwareAddress" />
        </div>
        <ion-grid v-else-if="installStatus=== STATUS_SUCCESS">
          <ion-row class="ion-align-items-center ion-justify-content-center full-height">
            <div>
              <img :src="$asset('/images/bridge_setup_success.svg')" class="success-container" v-svg-inject />
              <p class="ion-text-center">
                {{ $t('settings.convertor.detect.Success', { hardwareAddress: convertor.hardwareAddress }) }}
              </p>
            </div>
          </ion-row>
        </ion-grid>
        <ion-grid v-else-if="installStatus=== STATUS_FAILURE">
          <ion-row class="full-height ion-align-items-center ion-justify-content-center ">
            <div class="text-center full-width">
              <ion-text>
                <p>{{ $t('settings.convertor.detect.Failed') }}</p>
              </ion-text>
            </div>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <gro-button fill="clear"
                    @click="close">{{ $t('common.Cancel') }}
        </gro-button>
        <gro-button v-if="installStatus === STATUS_SUCCESS"
                    @click="close">{{ $t('common.Done') }}
        </gro-button>
        <gro-button v-else :disabled="!form.groupUuid"
                    @click="save">{{ $t('common.Save') }}
        </gro-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import DetectConvertorForm from '~gro-components/forms/Convertor/DetectConvertorForm'
import InstallConvertorForm from '~gro-components/forms/Convertor/InstallConvertorForm.vue'

const STATUS_RUNNING = 'RUNNING'
const STATUS_SUCCESS = 'SUCCESS'
const STATUS_FAILURE = 'FAILURE'
export default {
  components: {
    DetectConvertorForm,
    InstallConvertorForm,
  },
  data: () => ({
    convertor: null,
    success: false,
    installStatus: STATUS_RUNNING,
    form: {
      groupUuid: null,
      name: '',
    },
    STATUS_RUNNING,
    STATUS_SUCCESS,
    STATUS_FAILURE,
  }),
  props: {
    refetch: {
      type: Function,
    },
  },
  methods: {
    async save () {
      if (await this.$refs.form.install()) {
        this.installStatus = STATUS_SUCCESS
        return this.refetch()
      }
      this.installStatus = STATUS_FAILURE
      return this.refetch()
    },
    close () {
      this.$dismissAllModals()
    },
  },
}
</script>

<template>
  <SectionSkeleton v-if="loading" :rows="2" :columns="[2,2,2,4,null]" />
  <section v-else-if="convertors.length || virtualConverters.length">
    <h2>{{ $t('dashboard.convertor.Title') }}</h2>
    <ion-grid class="table">
      <ion-row class="head">
        <ion-col size="2">{{ $t('dashboard.convertor.Name') }}</ion-col>
        <ion-col size="2">{{ $t('dashboard.convertor.IP address') }}</ion-col>
        <ion-col size="2">{{ $t('dashboard.convertor.Hardware address') }}</ion-col>
        <ion-col size="4">{{ $t('dashboard.convertor.Section') }}</ion-col>
        <ion-col>{{ $t('dashboard.convertor.Last seen') }}</ion-col>
      </ion-row>

      <ion-row class="converters" v-for="(convertor, k) in convertors" :key="k">
        <ion-col size="2">{{ convertor.name }}</ion-col>
        <ion-col size="2">{{ convertor.ipAddress }}</ion-col>
        <ion-col size="2">{{ convertor.hardwareAddress }}</ion-col>
        <ion-col size="4">{{ convertor.group?.name }}</ion-col>
        <ion-col :class="{'error': isOld(convertor)}">
          <MomentFrom :date="convertor.lastSeen" />
        </ion-col>
        <ion-col v-if="$can('manageConvertors', 'bridge')">
          <ion-button
            @click="openConvertorSettingsModal(convertor)"
            size="small"
            fill="clear">
            <ion-icon name="settings-outline" />
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row class="converters" v-for="(virtualConverter, k) in virtualConverters" :key="k">
        <ion-col size="2">{{ virtualConverter.name }}</ion-col>
        <ion-col size="2"></ion-col>
        <ion-col size="2"></ion-col>
        <ion-col size="4">{{ virtualConverter.deviceGroup?.name || '-' }}</ion-col>
        <ion-col></ion-col>
        <ion-col v-if="$can('manageConvertors', 'bridge')">
          <ion-button
            @click="openVirtualConverterSettingsModal(virtualConverter)"
            size="small"
            fill="clear">
            <ion-icon name="settings-outline" />
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </section>
</template>

<script>
import SectionSkeleton from './SectionSkeleton'
import { convertorQueries } from '~gro-modules/Convertor'
import moment from 'moment'
import { computed } from 'vue'
import ConvertorSettingsModal from '../modals/ConvertorSettingsModal'
import ConvertorInstallModal from '../modals/ConvertorInstallModal'
import VirtualConverterSettingsModal from '../modals/VirtualConverterSettingsModal'

const OLD_THRESHOLD_MINUTES = 60

export default {
  setup () {
    const { convertors, convertersLoading, refetch: refectchConvertors } = convertorQueries.getConvertors()
    const {
      virtualConverters,
      virtualConvertersLoading,
      refetch: refectchVirtualConverters,
    } = convertorQueries.getVirtualConverters()
    const loading = computed(() => {
      return convertersLoading || virtualConvertersLoading
    })
    return {
      convertors,
      loading,
      virtualConverters,
      refectchConvertors,
      refectchVirtualConverters,
    }
  },
  components: { SectionSkeleton },
  methods: {
    isOld (convertor) {
      return moment().diff(convertor.lastSeen, 'minutes') > OLD_THRESHOLD_MINUTES
    },
    openConvertorSettingsModal (convertor) {
      return this.$showInModal(ConvertorSettingsModal, {
        hardwareAddress: convertor.hardwareAddress,
        refetch: () => this.refectchConvertors(),
      })
    },
    openVirtualConverterSettingsModal (virtualConverter) {
      return this.$showInModal(VirtualConverterSettingsModal, {
        virtualConverter,
        refetch: () => this.refectchVirtualConverters(),
      })
    },
    async addConvertor () {
      return this.$showInModal(ConvertorInstallModal, {
        refetch: () => this.refectchConvertors(),
      })
    },
  },
}
</script>

<template>
  <ion-page id="index">
    <DesktopHeader />
    <ion-content class="ion-padding">
      <ion-grid class="grid-dashboard">
        <deviceGroupSection ref="deviceGroupSection" />
        <unassignedDevicesSection />
        <bridgeSection ref="bridgeSection" />
        <gatewaySection ref="gatewaySection" />
        <smartboxSection ref="smartboxSection" />
        <convertorSection ref="convertorSection" />
      </ion-grid>
    </ion-content>
    <transition name="appear" v-if="buttons.length">
      <gro-fab style="transition: transform .8s ease; right: 50px; bottom:50px"
               @click="openMenu" />
    </transition>
  </ion-page>
</template>

<script>
import BridgeSection from './sections/BridgeSection'
import ConvertorSection from './sections/ConvertorSection'
import DeviceGroupSection from './sections/DeviceGroupSection'
import GatewaySection from './sections/GatewaySection'
import SmartboxSection from './sections/SmartboxSection'
import UnassignedDevicesSection from './sections/UnassignedDevicesSection'
import { egroQueries } from '~gro-modules/Egro'
import { VEG } from '~gro-modules/Bridge/growerTypes'

export default {
  components: {
    BridgeSection,
    ConvertorSection,
    DeviceGroupSection,
    GatewaySection,
    SmartboxSection,
    UnassignedDevicesSection,
  },
  data: () => ({ egroConnected: false, actionSheet: null }),
  computed: {
    buttons () {
      const buttons = []
      if (this.$can('add', 'device') && (!this.egroConnected || this.$growerType === VEG)) {
        buttons.push({
          text: this.$t('deviceGroup.Create'),
          handler: () => {
            this.actionSheet.dismiss()
            this.$refs.deviceGroupSection.addGroup()
          },
        })
      }
      if (this.$can('manageGateways', 'bridge')) {
        buttons.push({
          text: this.$t('deviceSettings.Add gateway'),
          handler: () => {
            this.actionSheet.dismiss()
            this.$refs.gatewaySection.addGateway()
          },
        })
      }
      if (this.$onsiteBridge && this.$can('manageConvertors', 'bridge')) {
        buttons.push({
          text: this.$t('deviceSettings.Add converter'),
          handler: () => {
            this.actionSheet.dismiss()
            this.$refs.convertorSection.addConvertor()
          },
        })
      }
      if (this.$onsiteBridge && this.$can('manageSmartboxes', 'bridge')) {
        buttons.push({
          text: this.$t('deviceSettings.Add smartbox'),
          handler: () => {
            this.actionSheet.dismiss()
            this.$refs.smartboxSection.addSmartbox()
          },
        })
      }
      return buttons
    },
  },
  methods: {
    async openMenu () {
      this.actionSheet = await this.$actionSheet(this.buttons)
    },
  },
  async mounted () {
    this.egroConnected = await egroQueries.isConnected(true)
  },
}
</script>
<style lang="scss">
.grid-dashboard {
  padding-bottom: 65px;
  width: 100%;
  max-width: 64rem;
  margin: 0px auto;

  h2 {
    font-size: 22px;
    font-weight: bold;
    padding-left: 8px;
    padding-top: 10px;
  }

  .button-conditions {
    min-width: 110px;
    min-height: 38px;
    --border-radius: 3px !important;
  }

  .head {
    color: var(--ion-color-medium);
    font-size: 14px;
    font-weight: bold;
  }

  ion-row:nth-child(even) {
    background: #f6f6f8
  }

  .name {
    color: var(--ion-color-medium);
    font-size: 14px;
    font-weight: bold;
    margin: auto;
  }

  .last-seen {
    font-size: 16px;
  }

  .error {
    &, .last-seen {
      color: red;
    }
  }

  .footer {
    margin: 10px 0px 0px 0px;
    font-weight: 700;
    color: var(--ion-color-dark);
    font-size: 16px;
    border-radius: 2px;
    border: solid 1px #d3d6df;
    background-color: #FFF !important;
  }
}

.modal-wrapper {
  min-height: 80vh !important;

  .animated-svg-container {
    max-width: 100% !important;
    height: 30vh !important;
    width: inherit !important;
    text-align: center;

    &.width-90vw {
      width: inherit !important;
    }

    svg {
      height: 100%;
    }
  }

  .width-90vw {
    width: inherit !important;
  }
}

.success-container {
  max-height: 20vh !important;
}

.text-center {
  text-align: center;
}
</style>

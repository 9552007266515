<template>
  <ion-grid class="table">
    <template v-if="measurements.length">
      <ion-row class="head">
        <ion-col size="2">{{ name }}</ion-col>
        <ion-col size="2">{{ $t('dashboard.device.sensor.Sn') }}</ion-col>
        <ion-col v-for="(sensorIconClass, index) in sensorIconClassNames"
                 :key="index"
                 :size="(index+1 < sensorIconClassNames.length) ? 1 : ''"
        >
          <span :class="sensorIconClass" class="icon-egro"></span>
        </ion-col>
        <ion-col v-if="sensorIconClassNames.length === 3" />
        <ion-col size="2">{{ $t('dashboard.device.sensor.Last seen') }}</ion-col>
        <ion-col />
        <ion-col />
      </ion-row>

      <DeviceSensors
        v-for="device in measurements"
        :key="device.serialNumber"
        :device="device"
        :sensors="sensors"
        :class="{'error': isOld(device)}"
        class="devices"
        @refetch="$emit('refetch')"
      />

      <ion-row class="footer" v-if="!noAverage">
        <ion-col offset="2" size="2">{{ $t('dashboard.device.sensor.Avg') }}</ion-col>
        <ion-col
          v-for="(sensor,index) in sensors"
          :key="index"
          :size="(index+1 < sensors.length) ? 1 : ''"
        >
          <SensorValue :type="sensor" :value="avgMeasurements[sensor]" no-label></SensorValue>
        </ion-col>
      </ion-row>
    </template>
    <template v-else>
      <ion-row class="head">
        <ion-col>{{ name }}</ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <i>{{ $t('dashboard.device.sensor.No sensors') }}</i>
        </ion-col>
      </ion-row>
    </template>

  </ion-grid>
</template>

<script>
import { ref, computed, watchEffect } from 'vue'
import moment from 'moment'
import { deviceSensorZone } from '~gro-modules/Device'
import { AvailableTypesHelper } from '~gro-modules/DeviceGroup'
import SensorValue from '~gro-components/SensorValue'
import DeviceSensors from './DeviceSensors'

const INACTIVE_AFTER_MINUTES = 60 * 60 // 1 hour

export default {
  components: { SensorValue, DeviceSensors },
  emits: ['refetch'],
  props: {
    type: {
      type: String,
      required: true,
    },
    sensors: {
      type: Array,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    noAverage: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const avgMeasurements = ref([])
    const measurements = computed(() => (props.group?.devices || []).reduce((measurements, device) => {
      if (deviceSensorZone(device.type) !== props.type) return measurements

      const sensors = device.sensors.reduce((sensors, { type, measurements }) => {
        if (measurements?.recent?.value === 0) {
          return {
            ...sensors,
            [type]: 0,
          }
        } else if (measurements?.recent?.value) {
          return {
            ...sensors,
            [type]: measurements?.recent?.value,
          }
        } else {
          return {
            ...sensors,
            [type]: '-',
          }
        }
      }, {})

      measurements.push({
        name: device.name,
        serialNumber: device.serialNumber,
        lastSeen: device.lastSeen,
        type: device.type,
        batteryPercentage: device.batteryPercentage,
        sensors: sensors,
      })

      return measurements
    }, []))

    const availableTypes = computed(() => {
      const types = []
      props.sensors.forEach(sensorType => {
        const hasType = !!props.group?.devices?.find(device => {
          return !!device.sensors.find(sensor => sensor.type === sensorType)
        })
        if (hasType) {
          types[sensorType] = null
        }
      })
      return types
    })

    watchEffect(() => {
      avgMeasurements.value = (props.group?.measurements?.recent || []).reduce((averages, {
        type,
        value,
      }) => ({
        ...averages,
        [type]: value,
      }), availableTypes.value)

      if (!props.group?.uuid) return
      AvailableTypesHelper.store(props.group.uuid, avgMeasurements.value)
    })

    const sensorIconClassNames = props.sensors.map((sensor) => sensor.toLowerCase().replace('_', '-'))

    return {
      measurements,
      avgMeasurements,
      sensorIconClassNames,
    }
  },

  methods: {
    isOld (device) {
      return moment().diff(device.lastSeen) > (INACTIVE_AFTER_MINUTES * 1000)
    },
  },
}
</script>

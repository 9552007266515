<template>
  <SectionSkeleton v-if="loading" :rows="2" :columns="[2,2,6,null]" />
  <section v-else-if="gateways.length">
    <h2>{{ $t('dashboard.gateway.Title') }}</h2>
    <ion-grid class="table">
      <ion-row class="head">
        <ion-col size="2">{{ $t('dashboard.gateway.Name') }}</ion-col>
        <ion-col size="2">{{ $t('dashboard.gateway.IP address') }}</ion-col>
        <ion-col size="6">{{ $t('dashboard.gateway.Hardware address') }}</ion-col>
        <ion-col>{{ $t('dashboard.gateway.Last seen') }}</ion-col>
      </ion-row>
      <ion-row class="gateways" v-for="(gateway, k) in gateways" :key="k">
        <ion-col size="2">{{ gateway.name }}</ion-col>
        <ion-col size="2">{{ gateway.ipAddress }}</ion-col>
        <ion-col size="6">{{ gateway.hardwareAddress }}</ion-col>
        <ion-col :class="{'error': isOld(gateway)}" class="err">
          <MomentFrom :date="gateway.lastSeen" />
        </ion-col>
        <ion-col v-if="$can('manageGateways', 'bridge')">
          <ion-button
            @click="openSettingsModal(gateway)"
            size="small"
            fill="clear">
            <ion-icon name="settings-outline" />
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </section>
</template>

<script>
import moment from 'moment'
import SectionSkeleton from './SectionSkeleton'
import { gatewayQueries } from '~gro-modules/Gateway'
import GatewaySettingsModal from '../modals/GatewaySettingsModal'
import GatewayInstallModal from '../modals/GatewayInstallModal.vue'
import GatewayClaimModal from '../modals/GatewayClaimModal.vue'

const OLD_THRESHOLD_MINUTES = 60

export default {
  setup () {
    const { gateways, loading, refetch } = gatewayQueries.getGateways(10 * 1000)
    return {
      gateways,
      loading,
      refetch,
    }
  },
  components: { SectionSkeleton },
  methods: {
    isOld (gateway) {
      return moment().diff(gateway.lastSeen, 'minutes') > OLD_THRESHOLD_MINUTES
    },
    openSettingsModal (gateway) {
      return this.$showInModal(GatewaySettingsModal, {
        hardwareAddress: gateway.hardwareAddress,
        refetch: () => {
          this.refetch()
        },
      })
    },
    addGateway () {
      if (this.$bridge.isOnsite()) {
        this.$showInModal(GatewayInstallModal, {
          refetch: () => {
            this.refetch()
          },
        })
      } else {
        this.$showInModal(GatewayClaimModal, {
          refetch: () => {
            this.refetch()
          },
        })
      }
    },
  },
}
</script>

<template>
  <template v-if="loading && !groups.length">
    <SectionSkeleton v-for="k in expectedData"
                     :key="k" />
  </template>
  <section v-else-if="groups.length" v-for="group in orderedGroups" :key="group.uuid">
    <ion-toolbar>
      <h2>{{ group.name }}</h2>
      <ion-buttons slot="end"
                   class="ion-padding-top">
        <ion-button
          v-if="$can('edit', 'deviceGroup')"
          @click="openGroupSettingsModal(group)"
          size="small"
          fill="clear">
          <ion-icon name="settings-outline" />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>

    <Devices
      :name="$t('dashboard.device.sensor.Rootzone')"
      :type="ROOT_ZONE"
      :group="group"
      :sensors="rootTypes"
      @refetch="refetch"
    ></Devices>

    <Devices
      :name="$t('dashboard.device.sensor.Climate')"
      :type="CLIMATE_ZONE"
      :group="group"
      :sensors="climateTypes"
      @refetch="refetch"
    ></Devices>

    <ion-toolbar>
      <ion-buttons slot="end">
        <gro-button
          @click="openConditionsPage(group)"
          color="cto"
          fill="solid"
          class="button-conditions"
        >{{ $t('dashboard.device.Conditions') }}
        </gro-button>
      </ion-buttons>
    </ion-toolbar>
  </section>
</template>

<script>
import SectionSkeleton from './SectionSkeleton'
import Devices from './Devices'
import DeviceGroupSettingsModal from '../modals/DeviceGroupSettingsModal'
import DeviceGroupAddModal from '../modals/DeviceGroupAddModal'
import { AvailableTypesHelper, deviceGroupQueries } from '~gro-modules/DeviceGroup'
import { SensorTypeHelper, ROOT_ZONE, CLIMATE_ZONE } from '~gro-modules/Sensor'
import { computed } from 'vue'

export default {
  setup () {
    const { groups, loading, refetch } = deviceGroupQueries.getAllWithLatestMeasurements()
    const orderedGroups = computed(() => [...groups.value].sort((groupA, groupB) => {
      if (groupA.devices.length > 0) return -1
      if (groupB.devices.length > 0) return 1
      return 0
    }))
    return {
      groups,
      orderedGroups,
      refetch,
      loading,
      expectedData: AvailableTypesHelper.amountStored,
      rootTypes: SensorTypeHelper.typesByZone[ROOT_ZONE],
      climateTypes: SensorTypeHelper.typesByZone[CLIMATE_ZONE],
      ROOT_ZONE,
      CLIMATE_ZONE,
    }
  },
  components: {
    Devices,
    SectionSkeleton,
  },
  methods: {
    async openConditionsPage (group) {
      await this.$storage.forget('conditionsView')
      this.$goto('conditions.show', { uuid: group.uuid })
    },
    async openGroupSettingsModal (group) {
      return this.$showInModal(DeviceGroupSettingsModal, {
        deviceGroup: group,
        refetch: () => this.refetch(),
      })
    },
    async addGroup () {
      return this.$showInModal(DeviceGroupAddModal, {
        refetch: () => this.refetch(),
      })
    },
  },
}
</script>

<template>
  <SectionSkeleton v-if="loading" />
  <section v-else-if="unassignedGroup.devices.length">
    <h2>{{ $t('dashboard.device.Unassigned') }}</h2>
      <Devices
        :name="$t('dashboard.device.sensor.Rootzone')"
        :type="ROOT_ZONE"
        :group="unassignedGroup"
        no-average
        :sensors="rootTypes"
      />
      <Devices
        :name="$t('dashboard.device.sensor.Climate')"
        :type="CLIMATE_ZONE"
        :group="unassignedGroup"
        no-average
        :sensors="climateTypes"
      />

    <ion-toolbar>
      <ion-buttons slot="end">
        <gro-button
          @click="openConditionsPage()"
          color="cto"
          fill="solid"
          class="button-conditions"
        >{{ $t('dashboard.device.Conditions') }}
        </gro-button>
      </ion-buttons>
    </ion-toolbar>
  </section>
</template>

<script>
import moment from 'moment'
import { computed } from 'vue'
import SectionSkeleton from './SectionSkeleton'
import { deviceQueries } from '~gro-modules/Device'
import { SensorTypeHelper, ROOT_ZONE, CLIMATE_ZONE } from '~gro-modules/Sensor'
import Devices from './Devices'
import { UNASSIGNED_UUID } from './../../conditions/partials/selection/DeviceGroupSelect'

const OLD_THRESHOLD_MINUTES = 60

export default {
  setup () {
    const { devices, loading } = deviceQueries.getUnassigned()

    const unassignedGroup = computed(() => ({
      devices: devices.value,
    }))
    return {
      unassignedGroup,
      loading,
      rootTypes: SensorTypeHelper.typesByZone[ROOT_ZONE],
      climateTypes: SensorTypeHelper.typesByZone[CLIMATE_ZONE],
      ROOT_ZONE,
      CLIMATE_ZONE,
    }
  },
  components: { Devices, SectionSkeleton },
  methods: {
    isOld (device) {
      return moment().diff(device.lastSeen, 'minutes') > OLD_THRESHOLD_MINUTES
    },
    async openConditionsPage () {
      await this.$storage.forget('conditionsView')
      this.$goto('conditions.show', { uuid: UNASSIGNED_UUID })
    },
  },
}
</script>

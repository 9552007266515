<template>
  <ion-page>
    <ion-content>
      <div class="ion-padding">
        <ion-grid v-if="success">
          <ion-row class="ion-align-items-center ion-justify-content-center full-height">
            <div>
              <img :src="$asset('/images/bridge_setup_success.svg')" class="success-container" v-svg-inject />
              <p class="ion-text-center">
                {{ $t('settings.smartbox.Success') }}
              </p>
            </div>
          </ion-row>
        </ion-grid>
        <ConnectSmartboxForm v-else ref="form" />
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <gro-button fill="clear"
                    @click="close">{{ $t('common.Cancel') }}
        </gro-button>
        <gro-button v-if="success" @click="close">
          {{ $t('common.Done') }}
        </gro-button>
        <gro-button v-else @click="submit"
                    :busy="isInstalling">{{ $t('common.Save') }}
        </gro-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import ConnectSmartboxForm from '~gro-components/forms/Smartbox/ConnectSmartboxForm.vue'

export default {
  components: {
    ConnectSmartboxForm,
  },
  props: {
    refetch: {
      type: Function,
    },
  },
  data: () => ({
    convertor: null,
    isInstalling: false,
    success: false,
    form: {
      groupUuid: null,
      name: '',
    },
  }),
  methods: {
    async submit () {
      this.isInstalling = true
      if (await this.$refs.form.submit()) {
        this.success = true
        return this.refetch()
      }
      this.isInstalling = false
    },
    close () {
      this.$dismissAllModals()
    },
  },
}
</script>

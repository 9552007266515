<template>
  <section>
    <h2>
      <skeleton />
    </h2>
    <ion-grid class="table">
      <ion-row class="head">
        <ion-col v-for="(column, index) in columns" :key="index" :size="column">
          <skeleton />
        </ion-col>
      </ion-row>
      <ion-row v-for="row in rows" :key="row">
        <ion-col v-for="(column, index) in columns" :key="index" :size="column">
          <skeleton />
        </ion-col>
      </ion-row>
    </ion-grid>
  </section>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 4,
    },
    columns: {
      type: Array,
      default: () => [2, 2, 1, 1, 1, null, 2],
    },
  },
}
</script>

<template>
  <ion-page>
    <ion-content>
      <div class="ion-padding">
        <UpdateGatewayFirmwareForm v-model:notUpdated="notUpdated"
                                   :hardwareAddress="hardwareAddress"
                                   :type="type" />
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <gro-button fill="clear"
                    @click="close">
          {{ $t('common.Cancel') }}
        </gro-button>
        <ion-buttons slot="end"
                     v-if="!notUpdated">
          <gro-button @click="close">
            {{ $t('common.Done') }}
          </gro-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import UpdateGatewayFirmwareForm from '~gro-components/forms/Gateway/onsite/UpdateGatewayFirmwareForm'
import { ref } from 'vue'

export default {
  props: {
    hardwareAddress: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
  },
  setup () {
    return {
      notUpdated: ref(true),
    }
  },
  components: {
    UpdateGatewayFirmwareForm,
  },
  methods: {
    close () {
      this.$dismissModal()
    },
  },
}
</script>

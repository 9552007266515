<template>
  <ion-page>
    <ion-content>
      <div class="ion-padding">
        <SmartboxForm :hardware-address="smartbox.hardwareAddress"
                     v-model:loading="loading"
                     ref="form" />
      </div>
    </ion-content>
    <ion-footer v-if="!loading">
      <ion-toolbar>
        <gro-button fill="clear"
                    @click="close">{{ $t('common.Cancel') }}
        </gro-button>
        <ion-buttons slot="end">
          <gro-button @click="save">{{ $t('common.Save') }}</gro-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import SmartboxForm from '~gro-components/forms/Smartbox/SmartboxForm'
import { ref } from 'vue'

export default {
  components: {
    SmartboxForm,
  },
  props: {
    smartbox: {
      type: Object,
      required: true,
    },
    refetch: {
      type: Function,
    },
  },
  setup () {
    return {
      loading: ref(false),
    }
  },
  methods: {
    close () {
      this.$dismissAllModals()
    },
    async save () {
      if (await this.$refs.form.submit()) {
        await this.refetch()
        return this.close()
      }
    },
  },
}
</script>

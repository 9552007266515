<template>
  <ion-page>
    <ion-content>
      <div class="ion-padding">
        <div class="setup-text">
          <h1>{{ $t('onboarding.bridge.gateway scan.Manual prompt') }}</h1>
        </div>
        <ion-item>
          <ion-label position="stacked">
            {{ $t('onboarding.bridge.gateway scan.Serial number input') }}
          </ion-label>
          <ion-input class="input-devicegroup"
                     v-model="form.serialNumber" />
        </ion-item>
        <ion-item>
          <ion-label position="stacked">
            {{ $t('onboarding.bridge.gateway scan.Hardware address input') }}
          </ion-label>
          <ion-input class="input-devicegroup"
                     v-model="form.hardwareAddress" />
        </ion-item>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <gro-button fill="clear"
                    @click="close">{{ $t('common.Cancel') }}
        </gro-button>
        <gro-button :disabled="!correctHardwareAddress || !form.serialNumber"
                    @click="claimGateway">{{ $t('common.Save') }}
        </gro-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { gatewayMutations } from '@/core-frontend/modules/Gateway'

export default {
  props: {
    refetch: {
      type: Function,
    },
  },
  data: () => ({
    form: {
      hardwareAddress: '',
      serialNumber: '',
    },
  }),
  computed: {
    correctHardwareAddress () {
      return (this.form.hardwareAddress.length === 12 ||
          this.form.hardwareAddress.length === 17) &&
        this.form.hardwareAddress.match(/^([0-9A-F]{2}:?){5}([0-9A-F]{2})$/i)
    },
  },
  methods: {
    close () {
      this.$dismissAllModals()
    },
    async claimGateway () {
      if (this.form.hardwareAddress.length === 12) {
        this.form.hardwareAddress = this.form.hardwareAddress.replace(/(.{2})/g, '$1:').slice(0, -1)
      }
      const claimResult = await gatewayMutations.claimGateway(this.form)
      if (claimResult.errors) {
        await this.$showGraphQLErrors(claimResult.errors)
        return
      }
      this.close()
      this.$toast(this.$t('settings.gateway.install.status.Success', {
        hardwareAddress: this.form.hardwareAddress,
      }))
    },
  },
}
</script>

<template>
  <ion-page>
    <ion-content>
      <div class="ion-padding">
        <ConvertorForm :hardwareAddress="hardwareAddress"
                       v-model:convertor="convertor"
                       v-model:hasConnectionProblem="hasConnectionProblem"
                       ref="form" />
        <div>
          <gro-button v-if="hasConnectionProblem"
                      expand="block"
                      fill="clear"
                      color="warning"
                      @click="reconfigureConvertor">
            {{ $t('settings.convertor.Fix issues') }}
          </gro-button>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <gro-button fill="clear"
                    @click="close">{{ $t('common.Cancel') }}
        </gro-button>
        <ion-buttons slot="end">
          <gro-button @click="deleteConvertor"
                      color="danger">{{ $t('convertor.Delete') }}
          </gro-button>
          <gro-button @click="save">{{ $t('common.Save') }}</gro-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import ConvertorForm from '~gro-components/forms/Convertor/ConvertorForm'
import ConvertorReconfigureModal from './ConvertorReconfigureModal'
import { ref } from 'vue'

export default {
  components: {
    ConvertorForm,
  },
  props: {
    hardwareAddress: {
      type: String,
      required: true,
    },
    refetch: {
      type: Function,
    },
  },
  setup () {
    return {
      convertor: ref({}),
      loading: ref(false),
      hasConnectionProblem: ref(false),
    }
  },
  methods: {
    close () {
      this.$dismissAllModals()
    },
    async reconfigureConvertor () {
      this.$showInModal(ConvertorReconfigureModal, {
        hardwareAddress: this.hardwareAddress,
      })
    },
    async save () {
      if (await this.$refs.form.submit()) {
        await this.refetch()
        return this.close()
      }
    },
    async deleteConvertor () {
      if (await this.$refs.form.deleteConvertor()) {
        await this.refetch()
        return this.close()
      }
    },
  },
}
</script>

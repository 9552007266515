<template>
  <ion-page>
    <ion-content>
      <div class="ion-padding">
        <ReconfigureConvertorForm
          :hardware-address="hardwareAddress"
          @cancel="close"
          v-model:isRunning="isRunning" />
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <gro-button fill="clear"
                    @click="close">
          {{ $t('common.Cancel') }}
        </gro-button>
        <ion-buttons slot="end">
          <gro-button @click="close" v-if="!isRunning">
            {{ $t('common.Done') }}
          </gro-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import ReconfigureConvertorForm from '~gro-components/forms/Convertor/ReconfigureConvertorForm'
import { ref } from 'vue'

export default {
  props: {
    hardwareAddress: {
      required: true,
      type: String,
    },
  },
  setup () {
    return {
      isRunning: ref(true),
    }
  },
  components: {
    ReconfigureConvertorForm,
  },
  methods: {
    close () {
      this.$dismissModal()
    },
  },
}
</script>

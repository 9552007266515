<template>
  <ion-page>
    <ion-content>
      <div class="ion-padding">
        <VirtualConverterForm :uuid="virtualConverter.uuid" ref="form" />
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <gro-button fill="clear"
                    @click="close">{{ $t('common.Cancel') }}
        </gro-button>
        <ion-buttons slot="end">
          <gro-button @click="deleteConverter"
                      color="danger">{{ $t('common.Delete') }}
          </gro-button>
          <gro-button @click="save">{{ $t('common.Save') }}</gro-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import VirtualConverterForm from '~gro-components/forms/Convertor/VirtualConverterForm'

export default {
  components: {
    VirtualConverterForm,
  },
  props: {
    virtualConverter: {
      type: Object,
      required: true,
    },
    refetch: {
      type: Function,
    },
  },
  methods: {
    close () {
      this.$dismissAllModals()
    },
    async save () {
      if (await this.$refs.form.submit()) {
        await this.refetch()
        return this.close()
      }
    },
    async deleteConverter () {
      if (await this.$refs.form.deleteConverter()) {
        await this.refetch()
        return this.close()
      }
    },
  },
}
</script>

<template>
  <ion-page>
    <ion-content>
      <div class="ion-padding">
        <div v-if="step === STEP_TYPE_SELECT"
             class="gateway-type-select">
          <GatewayTypeForm @select="selectType" />
        </div>
        <GatewayExplanationSlides v-else-if="step === STEP_EXPLAIN"
                                  :type="gatewayType"
                                  ref="slides"
                                  @finished="explanationFinished" />
        <DetectGatewayForm v-else-if="step === STEP_DETECT"
                           @installStarted="candidateInstalling"
                           @failed="failed"
                           :type="gatewayType" />
        <GatewayInstallingForm v-else-if="step === STEP_INSTALL"
                               :type="gatewayType"
                               :hardwareAddress="candidate.hardwareAddress"
                               @success="success"
                               @fail="failed"
                               class="text-center" />
        <ion-grid class="full-height" v-else-if="step === STEP_FAILED">
          <ion-row class="full-height ion-align-items-center ion-justify-content-center ">
            <div class="text-center full-width">
              <ion-text>
                <p v-if="unauthenticated">
                  {{ $t('settings.gateway.install.status.Unauthenticated') }}
                </p>
                <p v-else>
                  {{ $t('settings.gateway.install.status.Failed') }}
                </p>
              </ion-text>
            </div>
          </ion-row>
        </ion-grid>
        <ion-grid class="full-height" v-else-if="step === STEP_SUCCESS">
          <ion-row class="ion-align-items-center ion-justify-content-center full-height">
            <div>
              <img :src="$asset('/images/bridge_setup_success.svg')" class="success-container" v-svg-inject />
              <p class="ion-text-center">
                {{ $t('settings.gateway.install.status.Success', { hardwareAddress: candidate.hardwareAddress }) }}
              </p>
            </div>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <gro-button fill="clear"
                    :disabled="step === STEP_INSTALL"
                    @click="close">{{ $t('common.Cancel') }}
        </gro-button>
        <template v-if="step === STEP_EXPLAIN && slides">
          <gro-button fill="clear"
                      @click="previousSlide">{{ $t('common.Back') }}
          </gro-button>
          <gro-button :disabled="slides.locked"
                      @click="slides.nextSlide">{{ $t('common.Next') }}
          </gro-button>
        </template>
        <gro-button v-if="step === STEP_SUCCESS"
                    fill="clear"
                    @click="close">{{ $t('common.Done') }}
        </gro-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import GatewayTypeForm from '~gro-components/forms/Gateway/GatewayTypeForm'
import GatewayExplanationSlides from '~gro-components/forms/Gateway/GatewayExplanationSlides'
import GatewayInstallingForm from '~gro-components/forms/Gateway/onsite/GatewayInstallingForm'
import DetectGatewayForm from '~gro-components/forms/Gateway/onsite/DetectGatewayForm'
import { installStatus } from '~gro-modules/Gateway'
import { ref } from 'vue'

const STEP_TYPE_SELECT = 'TYPE_SELECT'
const STEP_EXPLAIN = 'EXPLAIN'
const STEP_DETECT = 'DETECT'
const STEP_INSTALL = 'INSTALL'
const STEP_FAILED = 'FAILED'
const STEP_SUCCESS = 'SUCCESS'
export default {
  components: { DetectGatewayForm, GatewayInstallingForm, GatewayExplanationSlides, GatewayTypeForm },
  props: {
    refetch: {
      type: Function,
    },
  },
  setup () {
    return {
      step: ref(STEP_TYPE_SELECT),
      gatewayType: ref(null),
      candidate: ref(null),
      slides: ref(null),
      unauthenticated: ref(false),
      STEP_TYPE_SELECT,
      STEP_EXPLAIN,
      STEP_DETECT,
      STEP_INSTALL,
      STEP_FAILED,
      STEP_SUCCESS,
    }
  },
  methods: {
    selectType (type) {
      this.gatewayType = type
      this.step = STEP_EXPLAIN
    },
    previousSlide () {
      if (!this.slides.onFirstSlide) {
        return this.slides.previousSlide()
      }
      this.step = STEP_TYPE_SELECT
    },
    explanationFinished () {
      this.step = STEP_DETECT
    },
    candidateInstalling (candidate) {
      this.candidate = candidate
      this.step = STEP_INSTALL
    },
    success () {
      this.refetch()
      this.step = STEP_SUCCESS
    },
    failed (status) {
      this.refetch()
      this.step = STEP_FAILED
      this.unauthenticated = status === installStatus.INSTALL_FAILED_UNAUTHENTICATED
    },
    close () {
      this.$dismissAllModals()
    },
  },
}
</script>

<style lang="scss">
.gateway-type-select {
  svg {
    max-height: 20vh !important;
    min-height: 300px;
  }
}
</style>

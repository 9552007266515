<template>
  <ion-page>
    <ion-content>
      <div class="ion-padding">
        {{ $t('deviceGroup.Create instruction') }}
      </div>
      <div class="ion-padding">
        <DeviceGroupForm auto-generate-name ref="form" />
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <gro-button fill="clear"
                    @click="close">
          {{ $t('common.Cancel') }}
        </gro-button>
        <ion-buttons slot="end">
          <gro-button @click="saveGroup">
            {{ $t('deviceGroup.Create') }}
          </gro-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import DeviceGroupForm from '~gro-components/forms/DeviceGroupForm'

export default {
  props: {
    refetch: {
      type: Function,
    },
  },
  components: {
    DeviceGroupForm,
  },
  methods: {
    close () {
      this.$dismissAllModals()
    },
    async saveGroup () {
      if (await this.$refs.form.submit()) {
        this.refetch()
        return this.close()
      }
    },
  },
}
</script>

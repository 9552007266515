<template>
  <SectionSkeleton v-if="loading" :rows="2" :columns="[2,2,6,null]" />
  <section v-else-if="smartboxes.length">
    <h2>{{ $t('dashboard.smartbox.Title') }}</h2>
    <ion-grid class="table">
      <ion-row class="head">
        <ion-col size="2">{{ $t('dashboard.smartbox.Name') }}</ion-col>
        <ion-col size="2">{{ $t('dashboard.smartbox.IP address') }}</ion-col>
        <ion-col size="6">{{ $t('dashboard.smartbox.Hardware address') }}</ion-col>
        <ion-col></ion-col>
      </ion-row>
      <ion-row class="smartboxes" v-for="(smartbox, k) in smartboxes" :key="k">
        <ion-col size="2">{{ smartbox.name }}</ion-col>
        <ion-col size="2">{{ smartbox.ipAddress }}</ion-col>
        <ion-col size="6">{{ smartbox.hardwareAddress }}</ion-col>
        <ion-col></ion-col>
        <ion-col v-if="$can('manageSmartboxes', 'bridge')">
          <ion-button
            @click="openSettingsModal(smartbox)"
            size="small"
            fill="clear">
            <ion-icon name="settings-outline" />
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </section>
</template>

<script>
import SectionSkeleton from './SectionSkeleton'
import { smartboxQueries } from '~gro-modules/Smartbox'
import SmartboxSettingsModal from '../modals/SmartboxSettingsModal'
import SmartboxInstallModal from '../modals/SmartboxInstallModal'

export default {
  setup () {
    const { smartboxes, loading, refetch } = smartboxQueries.getSmartboxes(10 * 1000)
    return {
      smartboxes,
      loading,
      refetch,
    }
  },
  components: { SectionSkeleton },
  methods: {
    openSettingsModal (smartbox) {
      return this.$showInModal(SmartboxSettingsModal, {
        smartbox,
        refetch: () => this.refetch(),
      })
    },
    addSmartbox () {
      return this.$showInModal(SmartboxInstallModal, {
        refetch: () => this.refetch(),
      })
    },
  },
}
</script>

<template>
  <ion-row class="device">
    <ion-col v-if=device.name class="name" size="2">
      {{ device.name }}
    </ion-col>
    <ion-col v-else class="name" size="2">
      {{ device.serialNumber }}
    </ion-col>
    <ion-col size="2">{{ device.serialNumber }}</ion-col>
    <ion-col
      v-for="(sensor, k) in sensors"
      :key="k"
      :size="(k+1 < sensors.length) ? 1 : ''"
    >
      <SensorValue class="desktop" :value="device.sensors[sensor]" no-label :type="sensor"></SensorValue>
    </ion-col>
    <ion-col v-if="sensors.length === 3" />
    <ion-col class="last-seen" size="2">
      <MomentFrom :date="device.lastSeen" />
    </ion-col>
    <ion-col class="name" :class="{ error: batteryIconClass === 'battery-0-red' }">
      <template v-if="device.batteryPercentage !== null">
        <span :class="batteryIconClass" class="icon-egro tooltip">
          <div class="top">
            <p> {{ device.batteryPercentage }} %</p>
          </div>
        </span>
      </template>
    </ion-col>
    <ion-col>
      <ion-button
        v-if="$can('edit', 'device')"
        @click="openDeviceSettingsModal(device)"
        size="small"
        fill="clear">
        <ion-icon name="settings-outline" />
      </ion-button>
    </ion-col>
  </ion-row>
</template>

<script>
import SensorValue from '~gro-components/SensorValue'
import DeviceSettingsModal from '../modals/DeviceSettingsModal'

export default {
  emits: ['refetch'],
  components: { SensorValue },
  props: {
    device: {
      type: Object,
      required: true,
    },
    sensors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    batteryIconClass () {
      if (this.device.batteryPercentage === null) {
        return ''
      }

      const percentage = parseInt(this.device.batteryPercentage)

      if (percentage < 20) {
        return 'battery-0-red'
      } else if (percentage < 38) {
        return 'battery-25'
      } else if (percentage < 63) {
        return 'battery-50'
      } else if (percentage < 88) {
        return 'battery-75'
      } else {
        return 'battery-100'
      }
    },
  },
  methods: {
    async openDeviceSettingsModal (device) {
      return this.$showInModal(DeviceSettingsModal, {
        device,
        refetch: () => {
          this.$emit('refetch')
        },
      })
    },
  },
}
</script>

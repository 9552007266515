<template>
  <SectionSkeleton v-if="loading" :rows="1" :columns="[2,2,null]"/>
  <section v-else>
    <h2>{{ $t('dashboard.bridge.Title') }}</h2>
    <ion-grid class="table">
      <ion-row class="head">
        <ion-col size="2">{{ $t('dashboard.bridge.Name') }}</ion-col>
        <ion-col size="2">{{ $t('dashboard.bridge.IP address') }}</ion-col>
        <ion-col>{{ $t('dashboard.bridge.Sn') }}</ion-col>
      </ion-row>

      <ion-row class="bridge">
        <ion-col size="2">{{ facilityName }}</ion-col>
        <ion-col size="2">{{ ipAddresses?.length ? ipAddresses[0] : '-' }}</ion-col>
        <ion-col>{{ serialNumber }}</ion-col>
      </ion-row>
    </ion-grid>
  </section>
</template>
<script>
import SectionSkeleton from './SectionSkeleton'
import { bridgeQueries } from '~gro-modules/Bridge'

export default {
  setup () {
    const {
      facilityName,
      serialNumber,
      ipAddresses,
      _meta,
    } = bridgeQueries.getCurrent('facilityName', 'serialNumber', 'ipAddresses')

    return {
      facilityName,
      serialNumber,
      ipAddresses,
      loading: _meta.loading,
    }
  },
  components: { SectionSkeleton },
}
</script>

<template>
  <ion-page>
    <ion-content>
      <div class="ion-padding">
        <GatewayForm :hardware-address="hardwareAddress"
                     v-model:loading="loading"
                     v-model:gateway="gateway"
                     v-model:hasConnectionProblem="hasConnectionProblem"
                     ref="form" />
        <div v-if="!loading">
          <gro-button v-if="isOnsiteGateway && (hasConnectionProblem || hasFailedInstalling)"
                      expand="block"
                      fill="clear"
                      color="warning"
                      @click="reconfigureGateway">
            {{ $t('settings.gateway.Fix issues') }}
          </gro-button>

          <gro-button v-if="isOnsiteGateway"
                      expand="block"
                      fill="clear"
                      color="warning"
                      @click="updateFirmware">
            {{ $t('settings.gateway.Update firmware') }}
          </gro-button>
        </div>
      </div>
    </ion-content>
    <ion-footer v-if="!loading">
      <ion-toolbar>
        <gro-button fill="clear"
                    @click="close">{{ $t('common.Cancel') }}
        </gro-button>
        <ion-buttons slot="end">

          <gro-button @click="deleteGateway"
                      color="danger">{{ $t('settings.gateway.Delete') }}
          </gro-button>
          <gro-button @click="save">{{ $t('common.Save') }}</gro-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { ref, computed } from 'vue'
import { GATEWAY_MODE_ONSITE } from '~gro-modules/Gateway/modes'
import GatewayForm from '~gro-components/forms/Gateway/GatewayForm'
import installStatus from '~gro-modules/Gateway/installStatus'
import GatewayUpdateFirmwareModal from './GatewayUpdateFirmwareModal'
import GatewayReconfigureModal from './GatewayReconfigureModal'

export default {
  components: {
    GatewayForm,
  },
  props: {
    hardwareAddress: {
      type: String,
      required: true,
    },
    refetch: {
      type: Function,
    },
  },
  setup () {
    const gateway = ref({})
    const isOnsiteGateway = computed(() => {
      return gateway.value.mode === GATEWAY_MODE_ONSITE
    })
    const hasFailedInstalling = computed(() => {
      return [
        installStatus.INSTALL_FAILED,
        installStatus.INSTALL_FAILED_RECOVERABLE,
        installStatus.INSTALL_FAILED_UNAUTHENTICATED,
        installStatus.REINSTALL_FAILED,
        installStatus.REINSTALL_FAILED_RECOVERABLE,
      ].includes(gateway.value.installStatus)
    })

    return {
      gateway,
      isOnsiteGateway,
      hasFailedInstalling,
      loading: ref(false),
      hasConnectionProblem: ref(false),
    }
  },
  methods: {
    close () {
      this.$dismissAllModals()
    },
    async reconfigureGateway () {
      this.$showInModal(GatewayReconfigureModal, {
        hardwareAddress: this.gateway.hardwareAddress,
      })
    },
    async updateFirmware () {
      this.$showInModal(GatewayUpdateFirmwareModal, {
        hardwareAddress: this.gateway.hardwareAddress,
        type: this.gateway.type,
      })
    },
    async save () {
      if (await this.$refs.form.submit()) {
        await this.refetch()
        return this.close()
      }
    },
    async deleteGateway () {
      if (await this.$refs.form.deleteGateway()) {
        await this.refetch()
        return this.close()
      }
    },
  },
}
</script>
